﻿'use strict';

// toaster color
import {
    TOASTER_ERROR_BG_COLOR,
    TOASTER_SUCCESS_BG_COLOR
} from '../../constants';

const $CONFIRM_PAYMENT_FORM = $('#confirm-payment-form');

const $CONFIRM_PAYMENT_SUBMIT_BUTTON = $('[data-identity="payment-confirmation-submit-button"]');

const $CONFIRM_PAYMENT_INVOICE_NUMBER = $('[data-identity="payment-confirmation-invoice-number"]');


function OnBeginConfirmPayment() {
    $CONFIRM_PAYMENT_SUBMIT_BUTTON
        .addClass('disabled')
        .prop('disabled', true);
}

window.OnBeginConfirmPayment = OnBeginConfirmPayment;

function OnSuccessConfirmPayment(res) {

    $CONFIRM_PAYMENT_FORM
        .trigger('reset');

    Toaster({
        text: 'You have been successfully confirmed your payment!',
        duration: 5000,
        backgroundColor: TOASTER_SUCCESS_BG_COLOR,
        gravity: 'bottom',
        position: 'right'
    });

    $CONFIRM_PAYMENT_INVOICE_NUMBER.find(`[value="${res.id}"]`).remove();
}

window.OnSuccessConfirmPayment = OnSuccessConfirmPayment;

function OnFailureConfirmPayment(err) {
    $CONFIRM_PAYMENT_SUBMIT_BUTTON
        .removeClass('disabled')
        .prop('disabled', false);

    if (err.status == 400) {
        Toaster({
            text: err.statusText,
            duration: 5000,
            backgroundColor: TOASTER_ERROR_BG_COLOR,
            gravity: 'bottom',
            position: 'right'
        });
    }
    else {
        Toaster({
            text: 'There has been an error confirming your payment!',
            duration: 5000,
            backgroundColor: TOASTER_ERROR_BG_COLOR,
            gravity: 'bottom',
            position: 'right'
        });
    }
}

window.OnFailureConfirmPayment = OnFailureConfirmPayment;