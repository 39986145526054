﻿'use strict';

import * as _ from 'lodash/lodash.min.js';

import axios from 'axios';

import numeral from 'numeral';

//import 'bootstrap/dist/js/bootstrap.bundle';

import {
    GET_ALL_PROMO_URL,
    $AUTOMATIC_PROMOS_SUBMIT_BUTTON,
    $PROMO_MODAL,
    $PROMO_LOADER,
    $REMOVE_CART_PROMO,
    $REMOVE_SHIPPING_PROMO,
    $REMOVE_PRODUCT_PROMO,
    $REMOVE_COUPON_CODE,
    $REMOVE_MEMBERSHIP_POINT
} from '../constants';

import {
    TOASTER_ERROR_BG_COLOR,
    TOASTER_SUCCESS_BG_COLOR,
    NATIVE_SPINNER,
    NATIV_DARK_SPINNER,
} from '../../../constants';

let promos = undefined;

let cartPromos = [];

let productPromos = [];

let shippingPromos = [];

let selectedPromos = {
    cart: undefined,
    product: [],
    shipping: undefined
}


const REMOVE_CART_PROMO_URL = (promoId) => `/umbraco/surface/coupon/removecartpromo?promoId=${promoId}`;

const REMOVE_PRODUCT_PROMO_URL = (promoId, cartId) => `/umbraco/surface/coupon/removeproductpromo?promoId=${promoId}&cartId=${cartId}`;

const REMOVE_SHIPPING_PROMO_URL = (promoId) => `/umbraco/surface/coupon/removeshippingpromo?promoId=${promoId}`;

const REMOVE_COUPON_CODE_URL = (couponCode, promoId) => `/umbraco/surface/coupon/removecouponcode?couponCode=${couponCode}&promoId=${promoId}`;

const REMOVE_MEMBERSHIP_POINT_DISCOUNT_URL = () => `/umbraco/surface/coupon/removemembershippointdiscount`;

const PROMO_ITEM_HTML_CART_STRING = (promo) => {

    let html = `<label for="promo-${promo.id}" data-id="${promo.id}" data-promo-type="cart" class="promo">
                <input type="checkbox" name="promos" id="promo-${promo.id}" value="${promo.id}" />
                <span class="name">${promo.name}</span>
                <br />`;

    if (promo.discountAmount > 0) {

        html += `<span class="desc">Rp ${numeral(promo.discountAmount).format('#,#')} OFF YOUR SHOPPING TOTAL!</span>
            </label>`;

    }
    else if (promo.discountPercentage > 0) {

        if (promo.maxDiscount > 0) {

            html += `<span class="desc">${promo.discountPercentage}% OFF (MAX. Rp ${numeral(promo.maxDiscount).format('#,#')}) YOUR SHOPPING TOTAL!</span>
            </label>`;

        }
        else {

            html += `<span class="desc">${promo.discountPercentage}% OFF YOUR SHOPPING TOTAL!</span>
            </label>`;

        }

    }

    return html;

};

const PROMO_ITEM_HTML_PRODUCT_STRING = (promo) => {

    let html = `<label for="promo-${promo.id}" data-id="${promo.id}" data-variant-id="${promo.variantId}" data-cart-item-id="${promo.cartItemId}" data-promo-type="product" class="promo">
                <input type="checkbox" name="promos" id="promo-${promo.id}-${promo.cartItemId}" value="${promo.id}" />
                <span class="name">${promo.name}</span>
                <br />`;

    // if the discount product is not about giving
    // free items,
    // for example buy x get x for free
    if (promo.freeItems.length <= 0 && (promo.collectionId == undefined || promo.collectionId == null || promo.collectionId == 0)) {

        if (promo.discountAmount > 0) {

            html += `<span class="desc">Rp ${numeral(promo.discountAmount).format('#,#')} OFF TO <span data-identity="promo-from" data-variant-id="${promo.variantId}"></span>!</span>
            </label>`;

        }
        else if (promo.discountPercentage > 0) {

            if (promo.maxDiscount > 0) {

                html += `<span class="desc">${promo.discountPercentage}% OFF (MAX. Rp ${numeral(promo.maxDiscount).format('#,#')}) OFF TO <span data-identity="promo-from" data-variant-id="${promo.variantId}"></span>!</span>
            </label>`;

            }
            else {

                html += `<span class="desc">${promo.discountPercentage}% OFF TO <span data-identity="promo-from" data-variant-id="${promo.variantId}"></span>!</span>
            </label>`;

            }

        }

    }
    else {

        if (promo.discountAmount > 0) {

            html += `<span class="desc">Rp ${numeral(promo.discountAmount).format('#,#')} OFF IF YOU ADD ANOTHER <span data-identity="promo-from" data-variant-id="${promo.variantId}"></span> TO YOUR CART!</span>
            </label>`;

        }
        else if (promo.discountPercentage == 100 && promo.collectionId != null && promo.collectionId != undefined && promo.collectionId != 0) {

            html += `<span class="desc">FREE ${promo.discountQty} OF <span data-identity="promo-from" data-variant-id="${promo.variantId}"></span> FROM <span data-identity="promo-from-collection" data-collection-id="${promo.collectionId}"></span> COLLECTION!</span>
            </label>`;

        }
        else if (promo.discountPercentage == 100) {

            if (promo.maxDiscount > 0) {
                html += `<span class="desc">${promo.discountPercentage}% OFF FOR <span data-identity="promo-from" data-variant-id="${promo.variantId}"></span>          (MAX. Rp ${numeral(promo.maxDiscount).format('#,#')}) TO YOUR CART!</span>
                        </label>`;
            }
            else {
                html += `<span class="desc">FREE ${promo.discountQty} OF <span data-identity="promo-from" data-variant-id="${promo.variantId}"></span>!</span>
            </label>`;
            }

        }
        else {

            html += `<span class="desc">${promo.discountPercentage}% OFF FOR <span data-identity="promo-from" data-variant-id="${promo.variantId}"></span> TO YOUR CART!</span>
            </label>`;

        }

    }

    return html;

}

const PROMO_ITEM_HTML_SHIPPING_STRING = (promo) => {

    let html = `<label for="promo-${promo.id}" data-id="${promo.id}" data-promo-type="shipping" class="promo">
                <input type="checkbox" name="promos" id="promo-${promo.id}" value="${promo.id}" />
                <span class="name">${promo.name}</span>
                <br />`;

    if (promo.discountAmount > 0) {

        html += `<span class="desc">Rp ${numeral(promo.discountAmount).format('#,#')} OFF YOUR SHIPPING!</span>
            </label>`;

    }
    else if (promo.discountPercentage > 0) {

        if (promo.maxDiscount > 0) {

            html += `<span class="desc">${promo.discountPercentage}% OFF (MAX. Rp ${numeral(promo.maxDiscount).format('#,#')}) YOUR SHIPPING!</span>
            </label>`;

        }
        else {

            html += `<span class="desc">${promo.discountPercentage}% OFF YOUR SHIPPING!</span>
            </label>`;

        }

    }

    return html;

};

const $CURRENT_CHECKED_PROMOS = () => $('.promo.checked');

const $CURRENT_DISABLED_PROMOS = () => $('.promo.disabled');

const $CURRENT_CHECKED_CART_PROMOS = () => $('.promo.checked[data-type="cart"]');

const $CURRENT_CHECKED_PRODUCT_PROMOS = () => $('.promo.checked[data-type="product"]');

const $CURRENT_CHECKED_SHIPPING_PROMOS = () => $('.promo.checked[data-type="shipping"]');

const $PROMO_FROM = (variantId) => $(`[data-identity="promo-from"][data-variant-id="${variantId}"]`);

let printedAutomaticPromosCounter = 0;

function CollectPromos() {
    if (promos != undefined) {

        // checking discount for cart
        if (promos.DiscountCart.length > 0) {

            promos.DiscountCart.forEach(v => {

                let name = v.Discount.DiscountName;

                let id = v.Discount.ID;

                let isMultiple = v.Discount.AppliesMultiple;

                let discountPercentage = 0;

                let discountAmount = 0;

                let maxDiscount = 0;

                let type = 'cart';

                if (v.DiscountFree.AmountFreePercentage != null && v.DiscountFree.AmountFreePercentage > 0) {
                    discountPercentage = v.DiscountFree.AmountFreePercentage;
                }
                else if (v.DiscountFree.AmountFree != null && v.DiscountFree.AmountFree > 0) {
                    discountAmount = v.DiscountFree.AmountFree;
                }

                maxDiscount = v.DiscountFree.MaximumDiscount;

                cartPromos.push({
                    id,
                    name,
                    isMultiple,
                    discountAmount,
                    discountPercentage,
                    maxDiscount,
                    type
                })

            });

        }

        // checking discount for product
        if (promos.DiscountProduct.length > 0) {

            promos.DiscountProduct.forEach(v => {

                v.AvailableDiscounts.forEach(w => {

                    let name = w.Discount.DiscountName;

                    let id = w.Discount.ID;

                    let cartItemId = v.ID;

                    let variantId = v.VariantID;

                    let isMultiple = w.Discount.AppliesMultiple;

                    let discountPercentage = 0;

                    let discountAmount = 0;

                    let discountQty = 0;

                    let maxDiscount = 0;

                    let collectionId = 0;

                    let freeItems = [];

                    let type = 'product';

                    if (w.DiscountFree.AmountFreeQty > 0) {

                        if (w.DiscountFree.AmountFreePercentage == 100 && w.DiscountFree.AmountFreePercentage != null) {
                            if (w.DiscountFree.CollectionID == null) {
                                w.DiscountFreeDetail.forEach(x => {
                                    freeItems.push(x.VariantID);
                                });
                            }
                            else {
                                collectionId = w.DiscountFree.CollectionID;
                            }
                        }

                        discountPercentage = w.DiscountFree.AmountFreePercentage;

                        discountAmount = w.DiscountFree.AmountFree;

                        discountQty = w.DiscountFree.AmountFreeQty;

                    }
                    else if (w.DiscountFree.AmountFree > 0) {
                        discountAmount = w.DiscountFree.AmountFree;
                    }
                    else if (w.DiscountFree.AmountPercentage > 0) {
                        discountPercentage = w.DiscountFree.AmountFreePercentage;
                    }

                    maxDiscount = w.DiscountFree.MaximumDiscount;

                    productPromos.push({
                        name,
                        id,
                        cartItemId,
                        variantId,
                        isMultiple,
                        discountAmount,
                        discountPercentage,
                        discountQty,
                        collectionId,
                        maxDiscount,
                        freeItems,
                        type
                    });

                });

            });
        }

        // checking discount for shipping
        if (promos.DiscountShipping.length > 0) {

            promos.DiscountShipping.forEach(v => {

                let name = v.Discount.DiscountName;

                let id = v.Discount.ID;

                let isMultiple = v.Discount.AppliesMultiple;

                let discountAmount = 0;

                let type = 'shipping';

                if (v.DiscountFree.AmountFree > 0 && v.DiscountFree.AmountFree != null) {
                    discountAmount = v.DiscountFree.AmountFree;
                }

                shippingPromos.push({
                    name,
                    id,
                    isMultiple,
                    discountAmount,
                    type
                })
            });

        }

    }
}

function PopulateChosenPromo() {

    // gathering all checked promo
    $CURRENT_CHECKED_PROMOS()
        .each(function () {

            let id = $(this).data('id');

            let type = $(this).data('promoType');

            let cartItemId = $(this).data('cartItemId');

            if (type === 'cart') {

                selectedPromos.cart = cartPromos.find(x => x.id == id);

            }
            else if (type === 'product') {

                if (selectedPromos.product.find(x => x.id == id && x.cartItemId == cartItemId) == undefined) {

                    let productPromo = productPromos.find(x => x.id == id && x.cartItemId == cartItemId);

                    selectedPromos.product = [...selectedPromos.product, ...[productPromo]];

                }

            }
            else if (type === 'shipping') {

                selectedPromos.shipping = shippingPromos.find(x => x.id == id);

            }

        });

    console.log(selectedPromos);

}

function DePopulateChosenPromo(promoId, type, variantId = null, cartItemId = null) {

    if (type === 'cart') {

        selectedPromos.cart = undefined;

    }
    else if (type === 'product') {

        let index = selectedPromos.product.findIndex(x => x.id == promoId && x.variantId == variantId && x.cartItemId == cartItemId);

        selectedPromos.product.splice(index, 1);

    }
    else if (type === 'shipping') {

        selectedPromos.shipping = undefined;

    }

}

function CreatePromosView() {

    printedAutomaticPromosCounter = 0;

    if ((cartPromos.length == 1 && cartPromos[0].isMultiple && shippingPromos.findIndex(x => !x.isMultiple) < 0 && productPromos.findIndex(x => !x.isMultiple) < 0) || (cartPromos.length == 1 && !cartPromos[0].isMultiple && productPromos.length == 0 && shippingPromos.length == 0)) {
        selectedPromos.cart = cartPromos[0];
    }
    else {
        cartPromos.forEach(v => {
            let htmlElement = PROMO_ITEM_HTML_CART_STRING(v);

            $PROMO_MODAL.find('.promo-container').append(htmlElement);

            printedAutomaticPromosCounter++;
        });
    }

    let cartItemUnique = _.map(_.uniqBy(productPromos, 'cartItemId'), 'cartItemId');

    cartItemUnique.forEach(v => {

        let promos = productPromos.filter(x => x.cartItemId === v);

        if ((promos.length == 1 && promos[0].isMultiple && cartPromos.findIndex(x => !x.isMultiple) < 0 && shippingPromos.findIndex(x => !x.isMultiple) < 0) || (promos.length == 1 && !promos[0].isMultiple && cartPromos.length == 0 && shippingPromos.length == 0)) {
            selectedPromos.product.push(promos[0]);
        }
        else {
            promos.forEach(y => {
                let htmlElement = PROMO_ITEM_HTML_PRODUCT_STRING(y);

                $PROMO_MODAL.find('.promo-container').append(htmlElement);

                GetPromoFromName(y.variantId);

                printedAutomaticPromosCounter++;
            });
        }
    });

    if ((shippingPromos.length == 1 && shippingPromos[0].isMultiple && cartPromos.findIndex(x => !x.isMultiple) < 0 && productPromos.findIndex(x => !x.isMultiple) < 0) || (shippingPromos.length == 1 && !shippingPromos[0].isMultiple && productPromos.length == 0 && cartPromos.length == 0)) {
        selectedPromos.shipping = shippingPromos[0];
    }
    else {
        shippingPromos.forEach(v => {
            let htmlElement = PROMO_ITEM_HTML_SHIPPING_STRING(v);

            $PROMO_MODAL.find('.promo-container').append(htmlElement);

            printedAutomaticPromosCounter++;
        });
    }
}

function RearrangeAvailablePromo(promoObject) {

    let reenabledPromos = [];

    if (promoObject.type === 'cart') {

        if (selectedPromos.cart == undefined) {

            reenabledPromos = [...reenabledPromos, ...cartPromos.filter(x => x.id != promoObject.id)];

        }

    }
    else if (promoObject.type === 'product') {

        if (selectedPromos.product.length <= 0) {

            reenabledPromos = [...reenabledPromos, ...productPromos.filter(x => x.id != promoObject.id)];

        }
        else {

            reenabledPromos = [...reenabledPromos, ...productPromos.filter(x => x.variantId == promoObject.variantId
                && x.cartItemId == promoObject.cartItemId
                && x.id != promoObject.id)];

        }

    }
    else if (promoObject.type === 'shipping') {

        if (selectedPromos.shipping == undefined) {

            reenabledPromos = [...reenabledPromos, ...shippingPromos.filter(x => x.id != promoObject.id)];

        }

    }
    else return;

    reenabledPromos.forEach(promo => {

        if (promo.type === 'cart') {

            if (promo.isMultiple || (selectedPromos.product.length <= 0 && selectedPromos.shipping == undefined)) {

                $(`.promo[data-id="${promo.id}"]`).removeClass('disabled');

            }

        }
        else if (promo.type === 'shipping') {

            if (promo.isMultiple || (selectedPromos.product.length <= 0 && selectedPromos.cart == undefined)) {

                $(`.promo[data-id="${promo.id}"]`).removeClass('disabled');

            }

        }
        else if (promo.type === 'product') {

            if (promo.isMultiple || (selectedPromos.cart == undefined && selectedPromos.cart == undefined)) {

                $(`.promo[data-id="${promo.id}"][data-cart-item-id="${promo.cartItemId}"]`).removeClass('disabled');

            }

        }

    });

}

function AttachEventOnPromo() {
    $('.promo')
        .on('click', function (event) {
            if (event.target.tagName === "LABEL" || event.target.tagName === "SPAN") {

                if (!$(this).hasClass('disabled')) {

                    if (!$(this).hasClass('checked')) {

                        // promo id
                        let promoId = $(this).data('id');

                        let cartItemId = $(this).data('cartItemId');

                        // promo type
                        let promoType = $(this).data('promoType');

                        // selected promo
                        let thisPromo = undefined;

                        // other of same types promos
                        let otherSamePromos = [];

                        // other of different types promos
                        let otherDifferentPromos = [];

                        // finding other of same types promos
                        // and
                        // finding other of different types promos
                        if (promoType === 'cart') {

                            otherSamePromos = cartPromos.filter(x => x.id != promoId);

                            // find current promo
                            thisPromo = cartPromos.find(x => x.id == promoId);

                            // if this promo is
                            // able to be combined with other
                            // promo
                            if (thisPromo.isMultiple) {

                                otherDifferentPromos = [...otherDifferentPromos, ...productPromos.filter(x => !x.isMultiple)];

                                otherDifferentPromos = [...otherDifferentPromos, ...shippingPromos.filter(x => !x.isMultiple)];

                            }
                            else {

                                otherDifferentPromos = [...otherDifferentPromos, ...productPromos];

                                otherDifferentPromos = [...otherDifferentPromos, ...shippingPromos];

                            }

                        }
                        else if (promoType === 'product') {

                            otherSamePromos = productPromos.filter(x => x.id != promoId && x.cartItemId == cartItemId);

                            // find current promo
                            thisPromo = productPromos.find(x => x.id == promoId && x.cartItemId == cartItemId);

                            // if this promo is
                            // able to be combined with other
                            // promo
                            if (thisPromo.isMultiple) {

                                otherDifferentPromos = [...otherDifferentPromos, ...cartPromos.filter(x => !x.isMultiple)];

                                otherDifferentPromos = [...otherDifferentPromos, ...shippingPromos.filter(x => !x.isMultiple)];

                            }
                            else {

                                otherDifferentPromos = [...otherDifferentPromos, ...cartPromos];

                                otherDifferentPromos = [...otherDifferentPromos, ...shippingPromos];

                            }

                        }
                        else if (promoType === 'shipping') {

                            otherSamePromos = shippingPromos.filter(x => x.id != promoId);

                            // find current promo
                            thisPromo = shippingPromos.find(x => x.id == promoId);

                            // if this promo is
                            // able to be combined with other
                            // promo
                            if (thisPromo.isMultiple) {

                                otherDifferentPromos = [...otherDifferentPromos, ...cartPromos.filter(x => !x.isMultiple)];

                                otherDifferentPromos = [...otherDifferentPromos, ...productPromos.filter(x => !x.isMultiple)];

                            }
                            else {

                                otherDifferentPromos = [...otherDifferentPromos, ...cartPromos];

                                otherDifferentPromos = [...otherDifferentPromos, ...productPromos];

                            }
                        }

                        // disabling same type of promo
                        otherSamePromos.forEach(v => {

                            if (v.type === 'product') {

                                $(`.promo[data-id="${v.id}"][data-cart-item-id="${v.cartItemId}"]`).removeClass('checked').addClass('disabled');

                                $(`#promo-${v.id}-${v.cartItemId}`).prop('checked', false);

                            }
                            else {

                                $(`.promo[data-id="${v.id}"]`).removeClass('checked').addClass('disabled');

                                $(`#promo-${v.id}`).prop('checked', false);

                            }

                        });

                        // disabling other type of promo
                        otherDifferentPromos.forEach(v => {

                            $(`.promo[data-id="${v.id}"]`).removeClass('checked').addClass('disabled');

                            $(`#promo-${v.id}`).prop('checked', false);

                        });

                        // toggling checked class
                        $(this).addClass('checked');

                        // populate chosen promo
                        PopulateChosenPromo();

                    }
                    else {

                        // current unchecked id
                        let uncheckedId = $(this).data('id');

                        let uncheckedVariantId = $(this).data('variantId');

                        let uncheckedCartItemId = $(this).data('cartItemId');

                        let uncheckedType = $(this).data('promoType');

                        let promoObject;

                        if (uncheckedType === 'cart') {
                            promoObject = cartPromos.find(x => x.id == uncheckedId);
                        }
                        else if (uncheckedType === 'product') {
                            promoObject = productPromos.find(x => x.id == uncheckedId && x.variantId == uncheckedVariantId && x.cartItemId == uncheckedCartItemId);
                        }
                        else {
                            promoObject = shippingPromos.find(x => x.id == uncheckedId);
                        }



                        // depopulate
                        DePopulateChosenPromo(uncheckedId, uncheckedType, uncheckedVariantId, uncheckedCartItemId);

                        // toggling checked class
                        $(this).removeClass('checked');



                        if ($CURRENT_CHECKED_PROMOS().length) {

                            RearrangeAvailablePromo(promoObject, uncheckedType);

                        }
                        else {

                            $('.promo').removeClass('disabled');

                        }

                    }

                }

            }

        });
};

// getting product names
function GetProductName(variantId) {
    return axios.get(`/umbraco/surface/product/getproductnamefromvariantid?variantId=${variantId}`);
}

function GetPromoFromName(variantId) {
    GetProductName(variantId)
        .then(res => {
            let productName = res.data.productName;

            console.log(res.data);

            $PROMO_FROM(variantId)
                .text(productName);
        })
        .catch(err => {
            console.error(err);
        })
}

if ($PROMO_LOADER.length) {
    let jqxhr = $.get(GET_ALL_PROMO_URL, {
        beforeSend: function () {
            $PROMO_LOADER.addClass('active');
        }
    });

    jqxhr
        .done(res => {
            promos = res.data;

            console.log(promos);

            CollectPromos();

            CreatePromosView();

            $PROMO_LOADER.removeClass('active');

            AttachEventOnPromo();

            setTimeout(function () {
                if (printedAutomaticPromosCounter > 0) {
                    $PROMO_MODAL.modal('show');
                }
                else if (selectedPromos.cart != undefined || selectedPromos.shipping != undefined || selectedPromos.product.length > 0) {
                    let jqxhr = $.post('/umbraco/surface/coupon/automaticpromos', { model: selectedPromos });

                    jqxhr
                        .then(res => {
                            window.location.reload();
                        });
                }
            }, 300);
        });
}

if ($AUTOMATIC_PROMOS_SUBMIT_BUTTON.length) {
    $AUTOMATIC_PROMOS_SUBMIT_BUTTON
        .click(function () {
            let jqxhr = $.post('/umbraco/surface/coupon/automaticpromos', { model: selectedPromos });

            jqxhr
                .then(res => {
                    window.location.reload();
                });
        });
}


// removing promo
function RemoveCartPromo(promoId) {
    $.ajax({
        url: REMOVE_CART_PROMO_URL(promoId),
        method: 'DELETE',
        success: () => {
            window.location.reload();
        },
        error: (err) => {

            if (err.status == 400) {
                Toaster({
                    text: err.statusText,
                    position: 'right',
                    gravity: 'bottom',
                    duration: 5000,
                    backgroundColor: TOASTER_ERROR_BG_COLOR
                });
            }
            else if (err.status == 500) {

                console.error(err.statusText);

                Toaster({
                    text: 'There has been an error!',
                    position: 'right',
                    gravity: 'bottom',
                    duration: 5000,
                    backgroundColor: TOASTER_ERROR_BG_COLOR
                });
            }

        }
    });
};

function RemoveShippingPromo(promoId) {
    $.ajax({
        url: REMOVE_SHIPPING_PROMO_URL(promoId),
        method: 'DELETE',
        success: () => {
            window.location.reload();
        },
        error: (err) => {

            if (err.status == 400) {
                Toaster({
                    text: err.statusText,
                    position: 'right',
                    gravity: 'bottom',
                    duration: 5000,
                    backgroundColor: TOASTER_ERROR_BG_COLOR
                });
            }
            else if (err.status == 500) {

                console.error(err.statusText);

                Toaster({
                    text: 'There has been an error!',
                    position: 'right',
                    gravity: 'bottom',
                    duration: 5000,
                    backgroundColor: TOASTER_ERROR_BG_COLOR
                });
            }

        }
    });
};

function RemoveProductPromo(promoId, cartId) {
    $.ajax({
        url: REMOVE_PRODUCT_PROMO_URL(promoId, cartId),
        method: 'DELETE',
        success: () => {
            window.location.reload();
        },
        error: (err) => {

            if (err.status == 400) {
                Toaster({
                    text: err.statusText,
                    position: 'right',
                    gravity: 'bottom',
                    duration: 5000,
                    backgroundColor: TOASTER_ERROR_BG_COLOR
                });
            }
            else if (err.status == 500) {

                console.error(err.statusText);

                Toaster({
                    text: 'There has been an error!',
                    position: 'right',
                    gravity: 'bottom',
                    duration: 5000,
                    backgroundColor: TOASTER_ERROR_BG_COLOR
                });
            }

        }
    });
}

function RemoveCouponCode(couponCode, promoId) {
    $.ajax({
        url: REMOVE_COUPON_CODE_URL(couponCode, promoId),
        method: 'DELETE',
        success: () => {
            window.location.reload();
        },
        error: (err) => {
            if (err.status == 400) {
                Toaster({
                    text: err.statusText,
                    position: 'right',
                    gravity: 'bottom',
                    duration: 5000,
                    backgroundColor: TOASTER_ERROR_BG_COLOR
                });
            }
            else if (err.status == 500) {

                console.error(err.statusText);

                Toaster({
                    text: 'There has been an error!',
                    position: 'right',
                    gravity: 'bottom',
                    duration: 5000,
                    backgroundColor: TOASTER_ERROR_BG_COLOR
                });
            }
        }
    });
}

function RemoveMembershipPointDiscount() {
    $.ajax({
        url: REMOVE_MEMBERSHIP_POINT_DISCOUNT_URL(),
        method: 'DELETE',
        success: () => {
            window.location.reload();
        },
        error: (err) => {
            if (err.status == 400) {
                Toaster({
                    text: err.statusText,
                    position: 'right',
                    gravity: 'bottom',
                    duration: 5000,
                    backgroundColor: TOASTER_ERROR_BG_COLOR
                });
            }
            else if (err.status == 500) {

                console.error(err.statusText);

                Toaster({
                    text: 'There has been an error!',
                    position: 'right',
                    gravity: 'bottom',
                    duration: 5000,
                    backgroundColor: TOASTER_ERROR_BG_COLOR
                });
            }
        }
    });
}

if ($REMOVE_CART_PROMO().length) {
    $REMOVE_CART_PROMO().on('click', function () {

        let promoId = $(this).data('id');

        if ($(`[data-action="remove-coupon-code"][data-id="${promoId}"]`).length) {

            let $removeCouponCode = $(`[data-action="remove-coupon-code"][data-id="${promoId}"]`);

            let couponCode = $removeCouponCode.data('couponCode');

            RemoveCouponCode(couponCode, promoId);

        }
        else {
            RemoveCartPromo(promoId);
        }

    });
}

if ($REMOVE_PRODUCT_PROMO().length) {
    $REMOVE_PRODUCT_PROMO().on('click', function () {

        let promoId = $(this).data('id');

        let cartId = $(this).data('cartItemId');

        if ($(`[data-action="remove-coupon-code"][data-id="${promoId}"]`).length) {

            let $removeCouponCode = $(`[data-action="remove-coupon-code"][data-id="${promoId}"]`);

            let couponCode = $removeCouponCode.data('couponCode');

            RemoveCouponCode(couponCode, promoId);

        }
        else {

            RemoveProductPromo(promoId, cartId);

        }

    });
}

if ($REMOVE_SHIPPING_PROMO().length) {
    $REMOVE_SHIPPING_PROMO().on('click', function () {

        let promoId = $(this).data('id');

        if ($(`[data-action="remove-coupon-code"][data-id="${promoId}"]`).length) {

            let $removeCouponCode = $(`[data-action="remove-coupon-code"][data-id="${promoId}"]`);

            let couponCode = $removeCouponCode.data('couponCode');

            RemoveCouponCode(couponCode, promoId);

        }
        else {

            RemoveShippingPromo(promoId);

        }

    });
}

if ($REMOVE_COUPON_CODE().length) {
    $REMOVE_COUPON_CODE().on('click', function () {

        let couponCode = $(this).data('couponCode');

        let promoId = $(this).data('id');

        RemoveCouponCode(couponCode, promoId);

    });
}

if ($REMOVE_MEMBERSHIP_POINT().length) {
    $REMOVE_MEMBERSHIP_POINT().on('click', function () {

        RemoveMembershipPointDiscount();

    })
}
