﻿'use strict';

import {
    TOASTER_ERROR_BG_COLOR,
    TOASTER_SUCCESS_BG_COLOR,
    TOASTER_SUCCESS_BG_COLOR_ALT
} from '../../constants';

import {
    $WISHLIST_REMOVE_BUTTON,
    $WISHLIST_SUBMIT_BUTTON,
    ADD_WISHLIST_URL,
    REMOVE_WISHLIST_URL,
    SUCCESS_ADD_WISHLIST_MESSAGE,    SUCCESS_REMOVE_WISHLIST_MESSAGE
} from './constants';

export function AddWishlist($elem) {
    let productId = $elem.data('productId');

    let callback = $elem.data('callback');

    let jqxhr = $.post(ADD_WISHLIST_URL, { productId });

    jqxhr.always(() => {

        switch (jqxhr.status) {
            case 200:
                Toaster({
                    text: SUCCESS_ADD_WISHLIST_MESSAGE,
                    backgroundColor: TOASTER_SUCCESS_BG_COLOR_ALT,
                    duration: 5000,
                    gravity: 'bottom',
                    position: 'right'
                });

                if (callback) window[callback].call(this, $elem, productId);

                $elem.each(function () {

                    $(this).data('action', 'remove-wishlist');
                    $(this).addClass('wishlisted');
                    $(this).off('click');
                    $(this).click(function () {
                        RemoveWishlist($(this));
                    });

                });

                break;
            case 400 || 401 || 403:
                Toaster({
                    text: jqxhr.statusText,
                    backgroundColor: TOASTER_ERROR_BG_COLOR,
                    duration: 5000,
                    gravity: 'bottom',
                    position: 'right'
                });
                break;
            case 500:
                Toaster({
                    text: 'There has been an error!',
                    backgroundColor: TOASTER_ERROR_BG_COLOR,
                    duration: 5000,
                    gravity: 'bottom',
                    position: 'right'
                });
                break;
        }

    });
}

export function RemoveWishlist($elem) {
    let productId = $elem.data('productId');

    let callback = $elem.data('callback');

    $.ajax({
        url: REMOVE_WISHLIST_URL + `?productId=${productId}`,
        method: 'DELETE',
        success: () => {
            Toaster({
                text: SUCCESS_REMOVE_WISHLIST_MESSAGE,
                backgroundColor: TOASTER_SUCCESS_BG_COLOR_ALT,
                duration: 5000,
                gravity: 'bottom',
                position: 'right'
            });

            if (callback) window[callback].call(this, $elem, productId);

            $elem.each(function () {

                $(this).data('action', 'add-wishlist');
                $(this).removeClass('wishlisted');
                $(this).off('click');
                $(this).click(function () {
                    AddWishlist($(this));
                });

            });
        },
        error: (jqxhr) => {

            switch (jqxhr.status) {
                case 400 || 401 || 403:
                    Toaster({
                        text: jqxhr.statusText,
                        backgroundColor: TOASTER_ERROR_BG_COLOR,
                        duration: 5000,
                        gravity: 'bottom',
                        position: 'right'
                    });
                    break;
                case 500:
                    Toaster({
                        text: 'There has been an error!',
                        backgroundColor: TOASTER_ERROR_BG_COLOR,
                        duration: 5000,
                        gravity: 'bottom',
                        position: 'right'
                    });
                    break;
            }
        }
    })
}

if ($WISHLIST_SUBMIT_BUTTON().length) {
    $WISHLIST_SUBMIT_BUTTON().on('click', function () {

        AddWishlist($(this));

    });
}

if ($WISHLIST_REMOVE_BUTTON().length) {
    $WISHLIST_REMOVE_BUTTON().on('click', function () {

        RemoveWishlist($(this));

    });
}
