﻿'use strict';

import axios from 'axios';

import numeral from 'numeral';

import * as _ from 'lodash';

import * as UTILS from './utils';

import {
    IsVariantAvailable,
    $CART_QUANTITY_ELEMENTS,    $CART_ITEM_QUANTITY_INPUT,
    $CART_ITEM_DELETE_ACTION_ELEMENT,
    $SIDECART_ITEM_CONTAINER,
    FAIL_ADD_CART_MESSAGE,
    ADD_TO_CART_URL,
    SUCCESS_ADD_MESSAGE
} from './constants';

import {
    AddToCart
} from './cart';

import {
    AddWishlist,
    RemoveWishlist
} from './wishlist';

import { UpdateCartItem, DeleteCartItem, CalculateCart } from './cart';

const PRODUCT_QUICK_VIEW = () => document.querySelectorAll('[data-action="quick-view"]');

const $QUICK_VIEW_MODAL = () => window.$('#modal-quickview');

const GET_PRODUCT_DATA = (id) => `/umbraco/surface/product/getproductcompletedata?productId=${id}`;

// product data elements
const PRODUCT_IMAGE_ELEMENTS = () => $('[data-identity="product-qv-image"]');

const PRODUCT_NAME_ELEMENTS = () => $('[data-identity="product-qv-name"]');

const PRODUCT_PRICE_ELEMENTS = () => $('[data-identity="product-qv-price"]');

const PRODUCT_DISCOUNT_ELEMENTS = () => $('[data-identity="product-qv-discount"]');

const PRODUCT_SKU_ELEMENTS = () => $('[data-identity="product-qv-sku"]');

const PRODUCT_OPT1_LABEL = () => $('[data-identity="product-qv-opt1-label"]');

const PRODUCT_OPT2_LABEL = () => $('[data-identity="product-qv-opt2-label"]');

const PRODUCT_OPT3_LABEL = () => $('[data-identity="product-qv-opt3-label"]');

const PRODUCT_OPT1_CONTAINER = () => $('[data-identity="product-qv-opt1-container"]');

const PRODUCT_OPT2_CONTAINER = () => $('[data-identity="product-qv-opt2-container"]');

const PRODUCT_OPT3_CONTAINER = () => $('[data-identity="product-qv-opt3-container"]');

const PRODUCT_ACTION_CONTAINER = () => $('[data-identity="qv-action-container"]');

const LOADER = () => $('[data-identity="qv-loader"]');

const QUANTITY_REDUCE = () => $('[data-action="qv-reduce-cart-item-quantity"]');

const QUANTITY_ELEM = () => $('[data-action="qv-cart-item-quantity"]');

const QUANTITY_ADD = () => $('[data-action="qv-add-cart-item-quantity"]');

const ADD_TO_CART = () => $('[data-action="qv-add-cart"]');

const ADD_WISHLIST = () => $('[data-action="qv-add-wishlist"]');

const REMOVE_WISHLIST = () => $('[data-action="qv-remove-wishlist"]');

const OPTION_RADIOS = (variant, n) => `
                        <input type="radio" name="option${n}" id="option${n}-${variant}" data-action="qv-opt-${n}" value="${variant}" />
                        <label for="option${n}-${variant}"><span>${variant}</span></label>`;


// variant options
const $FIRST_OPTIONS_RADIO = () => $('[type="radio"][data-action="qv-opt-1"]');

const $FIRST_OPTIONS_SELECT = () => $('select[data-action="qv-opt-1"]');

const $SECOND_OPTIONS_RADIO = () => $('[type="radio"][data-action="qv-opt-2"]');

const $SECOND_OPTIONS_SELECT = () => $('select[data-action="qv-opt-2"]');

const $THIRD_OPTIONS_RADIO = () => $('[type="radio"][data-action="qv-opt-3"]');

const $THIRD_OPTIONS_SELECT = () => $('select[data-action="qv-opt-3"]');


// to keep the product data
let productData = undefined;

// temp selected variant
let tempSelectedVariant = undefined;

// temp selected option
let tempOption1 = undefined;
let tempOption2 = undefined;
let tempOption3 = undefined;

// selected variant
let selectedVariant = undefined;

// quantity of selected
let quantity = 1;


function resetSelectedVariant() {
    selectedVariant = undefined;
}


// enabling and disabling button
// for adding cart to item
function DisableAddToCartElement() {
    if (!ADD_TO_CART().hasClass('disabled')) {
        ADD_TO_CART()
            .addClass('disabled');
    }

}

function EnableAddToCartElement() {
    if (ADD_TO_CART().hasClass('disabled')) {
        ADD_TO_CART()
            .removeClass('disabled');
    }
}


// attaching data to html
function attachQuickViewData(data) {

    let firstVariant = data.Variants[0];

    // set the image
    PRODUCT_IMAGE_ELEMENTS()
        .css('background-image', `url(${data.ImageUrl})`);

    // set the name
    PRODUCT_NAME_ELEMENTS()
        .text(data.ProductName);

    // set the sku
    PRODUCT_SKU_ELEMENTS()
        .text(firstVariant.SKU);

    if (!firstVariant.IsHideComparePrice && firstVariant.CompareAtPrice > 0 && firstVariant.CompareAtPrice > firstVariant.Price) {

        // set the discount price
        PRODUCT_DISCOUNT_ELEMENTS()
            .text('Rp ' + numeral(firstVariant.CompareAtPrice).format('0,0'));

    }
    else {

        PRODUCT_DISCOUNT_ELEMENTS()
            .text('');

    }

    // set the price
    PRODUCT_PRICE_ELEMENTS()
        .text('Rp ' + numeral(firstVariant.Price).format('0,0'));

    if (data.Variants.length > 1) {

        if (data.Options.Option1Active) {

            // set the label
            PRODUCT_OPT1_LABEL()
                .text(data.Options.Option1);

            let firstOptions = _.uniqBy(data.Variants, 'Option1').map(x => x.Option1);

            if (firstOptions.length > 1) {

                firstOptions.forEach(x => {

                    PRODUCT_OPT1_CONTAINER()
                        .append(OPTION_RADIOS(x, '1'));

                });

            }

        }

        if (data.Options.Option2Active) {

            // set the label
            PRODUCT_OPT2_LABEL()
                .text(data.Options.Option2);

            let secondOptions = _.uniqBy(data.Variants, 'Option2').map(x => x.Option2);

            if (secondOptions.length > 1) {

                secondOptions.forEach(x => {

                    PRODUCT_OPT2_CONTAINER()
                        .append(OPTION_RADIOS(x, '2'));

                });

            }

        }

        if (data.Options.Option3Active) {

            // set the label
            PRODUCT_OPT3_LABEL()
                .text(data.Options.Option3);

            let thirdOptions = _.uniqBy(data.Variants, 'Option3').map(x => x.Option3);

            if (thirdOptions.length > 1) {

                thirdOptions.forEach(x => {

                    PRODUCT_OPT3_CONTAINER()
                        .append(OPTION_RADIOS(x, '3'));

                });

            }

        }

    }

}


// attaching functions for choosing
// variants
function attachOptionsFunctionality() {

    if (productData.Variants.length > 1) {

        DisableAddToCartElement();

        if ($FIRST_OPTIONS_RADIO().length) {
            $FIRST_OPTIONS_RADIO().on('click', function () {
                let $self = $(this);

                tempOption1 = $self.val();

                // reset seconds and third options
                $SECOND_OPTIONS_RADIO().prop('checked', false);
                $THIRD_OPTIONS_RADIO().prop('checked', false);

                // reset second and third options select
                $SECOND_OPTIONS_SELECT().val('');
                $THIRD_OPTIONS_SELECT().val('');

                // get all variant with the
                // chosen option 1
                tempSelectedVariant = productData.Variants.filter(v => v.Option1.toLowerCase() == tempOption1.toLowerCase());

                // if there is only one variants
                // which means no option 2
                if (tempSelectedVariant.length == 1) {
                    selectedVariant = tempSelectedVariant[0].Id;

                    if (IsVariantAvailable(tempSelectedVariant[0])) {

                        EnableAddToCartElement();

                    }

                    UTILS.UPDATE_PRICE_VIEW_FROM_VARIANT(tempSelectedVariant[0], PRODUCT_PRICE_ELEMENTS(), PRODUCT_DISCOUNT_ELEMENTS());

                }
                else {
                    // enabling the second and third options radios
                    $SECOND_OPTIONS_RADIO().prop('disabled', false);
                    $THIRD_OPTIONS_RADIO().prop('disabled', false);

                    // enabling the second and third options selects
                    $SECOND_OPTIONS_SELECT().find('option').prop('disabled', false).removeClass('disabled');
                    $THIRD_OPTIONS_SELECT().find('option').prop('disabled', false).removeClass('disabled');

                    resetSelectedVariant();

                    DisableAddToCartElement();

                    tempSelectedVariant.forEach((v) => {
                        if (!IsVariantAvailable(v)) {

                            // disable state if the variant is not available
                            $(`#option2-${v.Option2}`).prop('disabled', true);
                            $(`#option3-${v.Option3}`).prop('disabled', true);
                        }
                    });
                }
            })
        }

        if ($FIRST_OPTIONS_SELECT.length) {
            $FIRST_OPTIONS_SELECT.on('change', function () {
                let $self = $(this);

                tempOption1 = $self.val();

                // reset second and third options radio
                $SECOND_OPTIONS_RADIO().prop('checked', false);
                $THIRD_OPTIONS_RADIO().prop('checked', false);

                // reset second and third options select
                $SECOND_OPTIONS_SELECT().val('');
                $THIRD_OPTIONS_SELECT().val('');

                // get all variant with the
                // chosen option 1
                tempSelectedVariant = productData.Variants.filter(v => v.Option1.toLowerCase() == tempOption1.toLowerCase());

                // if there is only one variants
                // which means no option 2
                if (tempSelectedVariant.length == 1) {
                    selectedVariant = tempSelectedVariant[0].Id;

                    if (IsVariantAvailable(tempSelectedVariant[0])) {

                        EnableAddToCartElement();

                    }

                    UTILS.UPDATE_PRICE_VIEW_FROM_VARIANT(tempSelectedVariant[0], PRODUCT_PRICE_ELEMENTS(), PRODUCT_DISCOUNT_ELEMENTS());

                }
                else {
                    // enabling the second and third options radios
                    $SECOND_OPTIONS_RADIO().prop('disabled', false);
                    $THIRD_OPTIONS_RADIO().prop('disabled', false);

                    // enabling the second and third options selects
                    $SECOND_OPTIONS_SELECT().find('option').prop('disabled', false).removeClass('disabled');
                    $THIRD_OPTIONS_SELECT().find('option').prop('disabled', false).removeClass('disabled');

                    resetSelectedVariant();

                    DisableAddToCartElement();

                    tempSelectedVariant.forEach((v) => {
                        if (!IsVariantAvailable(v)) {

                            // disable state if the variant is not available
                            $(`#option2-${v.Option2}`).prop('disabled', true);
                            $(`#option3-${v.Option3}`).prop('disabled', true);
                        }
                    });
                }
            });
        }

        if ($SECOND_OPTIONS_RADIO().length) {
            $SECOND_OPTIONS_RADIO().on('click', function (e) {
                let $self = $(this);

                if (tempOption1 == undefined) {
                    e.preventDefault();

                    Notify('Please choose the first option!', 'error');

                    return;
                }

                tempOption2 = $self.val();

                // get all variant with the
                // chosen option 1 & option 2
                tempSelectedVariant = productData.Variants.filter(v => v.Option1.toLowerCase() == tempOption1.toLowerCase() &&
                    v.Option2.toLowerCase() == tempOption2.toLowerCase()
                );

                // if there is only one variants
                // which means no option 2
                if (tempSelectedVariant.length == 1) {
                    selectedVariant = tempSelectedVariant[0].Id;

                    if (IsVariantAvailable(tempSelectedVariant[0])) {

                        EnableAddToCartElement();

                    }

                    UTILS.UPDATE_PRICE_VIEW_FROM_VARIANT(tempSelectedVariant[0], PRODUCT_PRICE_ELEMENTS(), PRODUCT_DISCOUNT_ELEMENTS());

                }
                else {
                    // enabling the second options radio
                    $THIRD_OPTIONS_RADIO().prop('disabled', false);

                    // enabling the second options select
                    $THIRD_OPTIONS_SELECT().find('option').prop('disabled', false).removeClass('disabled');

                    resetSelectedVariant();

                    DisableAddToCartElement();

                    tempSelectedVariant.forEach((v) => {
                        if (!IsVariantAvailable(v)) {

                            // disable state if the variant is not available
                            $(`#option3-${v.Option3}`).prop('disabled', true);
                        }
                    });
                }
            })
        }

        if ($SECOND_OPTIONS_SELECT().length) {
            $SECOND_OPTIONS_SELECT().on('click', function (e) {
                let $self = $(this);

                if (tempOption1 == undefined) {
                    e.preventDefault();

                    Notify('Please choose the first option!', 'error');

                    return;
                }

                tempOption2 = $self.val();

                // get all variant with the
                // chosen option 1 & option 2
                tempSelectedVariant = productData.Variants.filter(v => v.Option1.toLowerCase() == tempOption1.toLowerCase() &&
                    v.Option2.toLowerCase() == tempOption2.toLowerCase()
                );

                // if there is only one variants
                // which means no option 2
                if (tempSelectedVariant.length == 1) {
                    selectedVariant = tempSelectedVariant[0].Id;

                    if (IsVariantAvailable(tempSelectedVariant[0])) {

                        EnableAddToCartElement();

                    }

                    UTILS.UPDATE_PRICE_VIEW_FROM_VARIANT(tempSelectedVariant[0], PRODUCT_PRICE_ELEMENTS(), PRODUCT_DISCOUNT_ELEMENTS());

                }
                else {
                    // enabling the second options radio
                    $THIRD_OPTIONS_RADIO().prop('disabled', false);

                    // enabling the second options select
                    $THIRD_OPTIONS_SELECT().find('option').prop('disabled', false).removeClass('disabled');

                    resetSelectedVariant();

                    DisableAddToCartElement();

                    tempSelectedVariant.forEach((v) => {
                        if (!IsVariantAvailable(v)) {

                            // disable state if the variant is not available
                            $(`#option3-${v.Option3}`).prop('disabled', true);
                        }
                    });
                }
            })
        }

        if ($THIRD_OPTIONS_RADIO().length) {
            $THIRD_OPTIONS_RADIO().on('click', function () {
                let $self = $(this);

                if (tempOption2 == undefined) {
                    e.preventDefault();

                    Notify('Please choose the second option!', 'error');

                    return;
                }

                tempOption3 = $self.val();

                // get all variant with the
                // chosen option 1 & option 2 & option 3
                tempSelectedVariant = productData.Variants.filter(v => v.Option1.toLowerCase() == tempOption1.toLowerCase() &&
                    v.Option2.toLowerCase() == tempOption2.toLowerCase() &&
                    v.Option3.toLowerCase() == tempOption3.toLowerCase()
                );

                // if there is only one variants
                if (tempSelectedVariant.length == 1) {
                    selectedVariant = tempSelectedVariant[0].Id;

                    if (IsVariantAvailable(tempSelectedVariant[0])) {

                        EnableAddToCartElement();

                    }

                    UTILS.UPDATE_PRICE_VIEW_FROM_VARIANT(tempSelectedVariant[0], PRODUCT_PRICE_ELEMENTS(), PRODUCT_DISCOUNT_ELEMENTS());

                }
            })
        }

        if ($THIRD_OPTIONS_SELECT().length) {
            $THIRD_OPTIONS_SELECT().on('click', function () {
                let $self = $(this);

                if (tempOption2 == undefined) {
                    e.preventDefault();

                    Notify('Please choose the second option!', 'error');

                    return;
                }

                tempOption3 = $self.val();

                // get all variant with the
                // chosen option 1 & option 2 & option 3
                tempSelectedVariant = productData.Variants.filter(v => v.Option1.toLowerCase() == tempOption1.toLowerCase() &&
                    v.Option2.toLowerCase() == tempOption2.toLowerCase() &&
                    v.Option3.toLowerCase() == tempOption3.toLowerCase()
                );

                // if there is only one variants
                if (tempSelectedVariant.length == 1) {
                    selectedVariant = tempSelectedVariant[0].Id;

                    if (IsVariantAvailable(tempSelectedVariant[0])) {

                        EnableAddToCartElement();

                    }

                    UTILS.UPDATE_PRICE_VIEW_FROM_VARIANT(tempSelectedVariant[0], PRODUCT_PRICE_ELEMENTS(), PRODUCT_DISCOUNT_ELEMENTS());

                }
            })
        }
    }
    else {

        selectedVariant = productData.Variants[0].Id;

        EnableAddToCartElement();

    }
}


// deselect all the choices
// of variants
function ResetChoices() {
    $(`label.selected`).removeClass('selected');


    // enabling options radios
    $FIRST_OPTIONS_RADIO()
        .removeClass('selected')
        .prop('checked', false)
        .prop('disabled', false);

    $SECOND_OPTIONS_RADIO()
        .removeClass('selected')
        .prop('checked', false)
        .prop('disabled', false);

    $THIRD_OPTIONS_RADIO()
        .removeClass('selected')
        .prop('checked', false)
        .prop('disabled', false);


    // enabling options selects
    $FIRST_OPTIONS_SELECT().find('option').prop('disabled', false).removeClass('disabled');
    $SECOND_OPTIONS_SELECT().find('option').prop('disabled', false).removeClass('disabled');
    $THIRD_OPTIONS_SELECT().find('option').prop('disabled', false).removeClass('disabled');


    // reset options select values
    $FIRST_OPTIONS_SELECT().val('');
    $SECOND_OPTIONS_SELECT().val('');
    $THIRD_OPTIONS_SELECT().val('');


    // emptying chosen options
    tempOption1 = undefined;
    tempOption2 = undefined;
    tempOption3 = undefined;


    // emptying chosen variants
    selectedVariant = undefined;
}


// adding and reducing quantity
// of item that would be added to cart
function AddQuantity(newQuantity) {
    if (newQuantity && newQuantity >= 0) {
        quantity = newQuantity;
    }
    else {
        quantity++;
    }
}

function ReduceQuantity(newQuantity) {
    if (newQuantity && newQuantity >= 0) {
        quantity = newQuantity;
    }
    else {
        quantity--;

        if (quantity < 0) {
            quantity = 0;
        }
    }
}

function attachQtyFunctionality() {

    if (QUANTITY_ADD()) {
        QUANTITY_ADD().off('click').on('click', function () {
            let $self = $(this);

            // get the exact quantity
            let tempQuantity = $self.data('quantity');

            AddQuantity(tempQuantity);

            DisplayQuantity(QUANTITY_ELEM());
        });
    }

    if (QUANTITY_REDUCE()) {
        QUANTITY_REDUCE().off('click').on('click', function () {
            let $self = $(this);

            // get the exact quantity
            let tempQuantity = $self.data('quantity');

            ReduceQuantity(tempQuantity);

            DisplayQuantity(QUANTITY_ELEM());
        });
    }

}


// displaying the quantity
function DisplayQuantity($elems) {
    $elems.each(function () {
        let $self = $(this);

        if ($self.is('input')) {
            $self.val(quantity);
        }
        else {
            $self.text(quantity);
        }
    });
}


// attaching wishlist and add to cart
// functionalities
function attachActionFunctionality() {

    // attach product ids
    ADD_TO_CART().first().data('productId', productData.Options.ProductId);
    ADD_WISHLIST().first().data('productId', productData.Options.ProductId);
    REMOVE_WISHLIST().first().data('productId', productData.Options.ProductId);

    console.log('test');

    // attach wishlist click handler
    ADD_WISHLIST().off('click').on('click', function () {
        AddWishlist($(this));
    });

    REMOVE_WISHLIST().off('click').on('click', function () {
        RemoveWishlist($(this));
    });

    // if current item is wishlisted
    if (productData.IsWishlisted) {
        ADD_WISHLIST().each(function () {
            $(this).data('action', 'qv-remove-wishlist');
            $(this).addClass('wishlisted');
            $(this).off('click');
            $(this).click(function () {
                RemoveWishlist($(this));
            });
        });

    }
    else {
        REMOVE_WISHLIST().each(function () {
            $(this).data('action', 'qv-add-wishlist');
            $(this).removeClass('wishlisted');
            $(this).off('click');
            $(this).click(function () {
                AddWishlist($(this));
            });
        });
    }

    // attach add to cart click handler
    ADD_TO_CART().off('click').on('click', function () {

        if (selectedVariant && productData.Options.ProductId && quantity) {

            AddToCart(productData.Options.ProductId, selectedVariant, quantity)
                .then(res => {
                    Notify(SUCCESS_ADD_MESSAGE, 'success');

                    $CART_QUANTITY_ELEMENTS().text(res.data.quantity);

                    if ($('.bw-empty-cart').length) {
                        $SIDECART_ITEM_CONTAINER().empty();
                    }

                    if ($SIDECART_ITEM_CONTAINER().find(`[data-identity="cart-item"][data-id="${res.data.item.ID}"]`).length) {
                        $SIDECART_ITEM_CONTAINER().find(`[data-identity="cart-item"][data-id="${res.data.item.ID}"]`).first().remove();
                    }

                    // appending new item
                    $SIDECART_ITEM_CONTAINER().append(UTILS.CART_ITEM_ELEMENT_STRING(res.data.item));

                    // add delete cart item handler
                    $CART_ITEM_DELETE_ACTION_ELEMENT()
                        .off()
                        .click(function () {
                            let id = $(this).data('id');
                            let isUnique = $(this).data('isUnique');
                            let prompt = $(this).data('prompt');

                            let itemInfo = {
                                imageUrl: $(`[data-identity="cart-item-image"][data-id="${id}"]`).data('imageUrl'),
                                productName: $(`[data-identity="cart-item-name"][data-id="${id}"]`).data('name')
                            };

                            console.log(itemInfo);

                            if (prompt) {
                                window[prompt].call(this, itemInfo)
                                    .then(res => {

                                        if (res) {
                                            if (isUnique != undefined && isUnique != null) {
                                                DeleteCartItem(id)
                                            }
                                            else {
                                                DeleteCartItem(id, isUnique);
                                            }
                                        }

                                    });
                            }
                        });

                    // add update cart item handler
                    $CART_ITEM_QUANTITY_INPUT()
                        .off('change')
                        .on('change', function () {
                            // logging
                            console.log('updating...');

                            // get the quantity
                            let qty = $(this).val();

                            if (qty <= 0) {
                                $(this).val(1);

                                // cancel the operation
                                return;
                            }

                            // get the cart id
                            let id = $(this).data('id');

                            // get unique
                            let isUnique = $(this).data('isUnique');

                            if (isUnique) {
                                UpdateCartItem(id, qty, isUnique);
                            }
                            else {
                                UpdateCartItem(id, qty);
                            }
                        });

                    // calculate subtotal cart
                    CalculateCart();

                    // clear selections
                    ResetChoices();

                    // redisabled the add to cart button
                    if (productData.Variants.length > 1) {
                        DisableAddToCartElement();
                    }
                    else {
                        EnableAddToCartElement();
                    }
                })
                .catch(err => {
                    console.log(err);

                    if (err.response.status == 400) {
                        Notify(err.response.statusText, 'error');
                    }
                    else {
                        console.error('Error code: ' + err.response.status);

                        Notify(FAIL_ADD_CART_MESSAGE, 'error');
                    }
                });
        }
        else if (selectedVariant == undefined || selectedVariant == null) {
            Notify('Please choose a variant!');
        }
        else if (productData.Options.ProductId == undefined || selectedVariant == null) {
            Notify('Please choose a product!');
        }
        else {
            Notify('Please specify a quantity!');
        }
    });

    // display actions
    PRODUCT_ACTION_CONTAINER().removeClass('d-none');
}


// showing and hiding 
// qv loader
function showLoader() {
    LOADER()
        .removeClass('hidden');
}

function hideLoader() {
    LOADER()
        .addClass('hidden');
}


// reseting the quick view html
function emptyView() {

    // emptying variant choices
    PRODUCT_OPT1_CONTAINER().empty();
    PRODUCT_OPT2_CONTAINER().empty();
    PRODUCT_OPT3_CONTAINER().empty();

    // resetting wishlist state
    REMOVE_WISHLIST().each(function () {
        $(this).data('action', 'qv-add-wishlist');
        $(this).removeClass('wishlisted');
        $(this).off('click');
        $(this).click(function () {
            AddWishlist($(this));
        });
    });

    // showing the loader
    showLoader();

}

if (PRODUCT_QUICK_VIEW().length > 0) {
    PRODUCT_QUICK_VIEW().forEach(elem => {

        elem.addEventListener('click', function (e) {

            e.preventDefault();

            // empty the view first
            emptyView();

            let id = elem.dataset.productId;

            if (id) {

                $QUICK_VIEW_MODAL().modal('show');

                axios.get(GET_PRODUCT_DATA(id))
                    .then(res => {

                        productData = res.data;

                        console.log(productData);

                        attachQuickViewData(productData);

                        attachOptionsFunctionality();

                        attachQtyFunctionality();

                        attachActionFunctionality();

                        hideLoader();
                    })
                    .catch(err => {

                        console.error(err);

                    });

            }

        });

    });
}