import * as UTILS from './utils';

import axios from 'axios';

import {
    TOASTER_ERROR_BG_COLOR,
    NATIVE_SPINNER
} from '../../constants';

import {
    SUCCESS_ADD_MESSAGE,
    $DELIVERY_COURIER_COST_INPUT,
    $DELIVERY_COURIER_NAME_INPUT,
    $DELIVERY_COURIER_SERVICE_INPUT,
    $DELIVERY_COURIER_RADIO,
    $DELIVERY_COURIER_SUBMIT_BUTTON,
    DELIVERY_COURIER_ERROR_MESSAGE,
    $PAYMENT_RADIO,
    $PAYMENT_BANK_INPUT,
    $PAYMENT_TYPE_INPUT,
    $PAYMENT_AND_NOTE_SUBMIT_BUTTON,
    PAYMENT_AND_NOTE_ERROR_MESSAGE,
    $COMPLETE_TRANSACTION_BUTTON,
    $PAY_WITH_MIDTRANS_BUTTON,
    $PAY_WITH_MIDTRANS_DEBIT_BUTTON,
    $CART_QUANTITY_ELEMENTS,
    $SIDECART_ITEM_CONTAINER,
    $CART_ITEM_DELETE_ACTION_ELEMENT,
    $CART_ITEM_QUANTITY_INPUT,
    IsVariantAvailable,    COMPLETE_TRANSACTION_URL
} from './constants';

import {
    CalculateCart,
    DeleteCartItem,
    UpdateCartItem,
    AddToCart
} from './cart';


// messages
const FAIL_ADD_MESSAGE = 'There has been an error adding your item to cart. Please contact our administrator!';
const FAIL_REMOVE_CART_ITEM_MESSAGE = 'Item has been failed to be deleted from cart!';
const CHOOSE_VARIANT_MESSAGE = 'Please choose a variant!';

/*
 * ==============================
 * Item Quantity Changes
 * ==============================
 *
 * Item quantity
 *
 */
let itemQuantity = 1;

/*
 * Elements for adding quantity.
 * Could only be button elements.
 *
 */
const $ADD_QUANTITY_ELEMENTS = $('button[data-action="bw-add-cart-item-quantity"]');

/*
 * Elements for reducing quantity.
 * Could only be button elements.
 *
 */
const $REDUCE_QUANTITY_ELEMENTS = $('button[data-action="bw-reduce-cart-item-quantity"]');

/*
 * Element for containing the quantity number.
 *
 */
const $QUANTITY_CONTAINER_ELEMENTS = $('[data-action="bw-cart-item-quantity"]');

const $SHIPPING_ADDRESS_SUBMIT_BUTTON = $('[data-action="submit-shipping-address"]');

/*
 * Adding quantity
 *
 */
function AddQuantity(newQuantity) {
    if (newQuantity && newQuantity >= 0) {
        itemQuantity = newQuantity;
    }
    else {
        itemQuantity++;
    }
}

/*
 * Reducing quantity
 *
 */
function ReduceQuantity(newQuantity) {
    if (newQuantity && newQuantity >= 0) {
        itemQuantity = newQuantity;
    }
    else {
        itemQuantity--;

        if (itemQuantity < 0) {
            itemQuantity = 0;
        }
    }
}

/*
 * Display the quantity number
 *
 */
function DisplayQuantity($elems) {
    $elems.each(function () {
        let $self = $(this);

        if ($self.is('input')) {
            $self.val(itemQuantity);
        }
        else {
            $self.text(itemQuantity);
        }
    });
}

if ($ADD_QUANTITY_ELEMENTS) {
    $ADD_QUANTITY_ELEMENTS.on('click', function () {
        let $self = $(this);

        // get the exact quantity
        let quantity = $self.data('quantity');

        AddQuantity(quantity);

        if ($QUANTITY_CONTAINER_ELEMENTS) {
            DisplayQuantity($QUANTITY_CONTAINER_ELEMENTS);
        }
    });
}

if ($REDUCE_QUANTITY_ELEMENTS) {
    $REDUCE_QUANTITY_ELEMENTS.on('click', function () {
        let $self = $(this);

        // get the exact quantity
        let quantity = $self.data('quantity');

        ReduceQuantity(quantity);

        if ($QUANTITY_CONTAINER_ELEMENTS) {
            DisplayQuantity($QUANTITY_CONTAINER_ELEMENTS);
        }
    });
}

/*
 * ==============================
 * Item Quantity Changes .\END
 * ==============================
 *
 */

/*
 * ==============================
 * Add To Cart
 * ==============================
 *
 */

/*
 * Url endpoint for adding item
 * to cart.
 *
 */
const ADD_TO_CART_URL = '/umbraco/surface/cart/addcart';

const ALL_VARIANTS_URL = '/umbraco/surface/product/getvariants';

let productVariants = [];

let currentlySelectedVariants = [];

let chosenVariant = undefined;

/*
 * Add to cart elements.
 * Could only be button elements.
 *
 */
const $ADD_TO_CART_ELEMENTS = $('button[data-action="bw-add-cart"]');

/*
 * Element that contain current
 * selected variant in product
 * detail
 *
 */
const $ADD_TO_CART_VARIANT_ELEMENTS = $('[data-action="bw-cart-item-variant"]');

/*
 * Elements that contains
 * first options of variants
 *
 */
const $VARIANT_OPTION_1_ELEMENTS = $('input[type="radio"][data-action="bw-cart-item-option1"]');
const $VARIANT_OPTION_1_SELECT_ELEMENTS = $('select[data-action="bw-cart-item-option1"]');

let option1 = undefined;

/*
 * Elements that contains
 * second options of variants
 *
 */
const $VARIANT_OPTION_2_ELEMENTS = $('input[type="radio"][data-action="bw-cart-item-option2"]');
const $VARIANT_OPTION_2_SELECT_ELEMENTS = $('select[data-action="bw-cart-item-option2"]');

let option2 = undefined;

/*
 * Elements that contains
 * third options of variants
 *
 */
const $VARIANT_OPTION_3_ELEMENTS = $('input[type="radio"][data-action="bw-cart-item-option3"]');
const $VARIANT_OPTION_3_SELECT_ELEMENTS = $('select[data-action="bw-cart-item-option3"]');

let option3 = undefined;

function EnableAllVariantOptions() {

    $VARIANT_OPTION_1_ELEMENTS.removeClass('disabled').prop('disabled', false);

    $VARIANT_OPTION_2_ELEMENTS.removeClass('disabled').prop('disabled', false);

    $VARIANT_OPTION_3_ELEMENTS.removeClass('disabled').prop('disabled', false);

}

function DisableAddToCartElement() {
    if (!$ADD_TO_CART_ELEMENTS.hasClass('disabled')) {
        $ADD_TO_CART_ELEMENTS
            .addClass('disabled');
    }

}

function EnableAddToCartElement() {
    if ($ADD_TO_CART_ELEMENTS.hasClass('disabled')) {
        $ADD_TO_CART_ELEMENTS
            .removeClass('disabled');
    }
}

let contentBefore = undefined;

function LoadSpinnerAddToCartElement() {

    if (($ADD_TO_CART_ELEMENTS.hasClass('disabled'))) {

        // save the content before
        // changed with spinner
        contentBefore = $ADD_TO_CART_ELEMENTS.html();

        // insert spinner
        $ADD_TO_CART_ELEMENTS.html(NATIVE_SPINNER);
    }

}

function UnloadSpinnerAddToCartElement() {

    // insert the content before
    $ADD_TO_CART_ELEMENTS.html(contentBefore);

    // clear the content before
    contentBefore = undefined;

}

function ResetChosenVariant() {
    chosenVariant = undefined;
}

function ResetChoices() {
    $(`label.selected`).removeClass('selected');

    // reset radios
    $VARIANT_OPTION_1_ELEMENTS
        .removeClass('selected')
        .prop('checked', false);

    $VARIANT_OPTION_2_ELEMENTS
        .removeClass('selected')
        .prop('checked', false);

    $VARIANT_OPTION_3_ELEMENTS
        .removeClass('selected')
        .prop('checked', false);

    // reset select
    $VARIANT_OPTION_1_SELECT_ELEMENTS
        .val('');
    $VARIANT_OPTION_2_SELECT_ELEMENTS
        .val('');
    $VARIANT_OPTION_3_SELECT_ELEMENTS
        .val('');

    // reset options chosen
    option1 = undefined;
    option2 = undefined;
    option3 = undefined;

    // reset selected variant
    ResetChosenVariant();

}

if ($ADD_TO_CART_ELEMENTS.length) {
    let productId = $ADD_TO_CART_ELEMENTS.first().data('productId');

    $.get(ALL_VARIANTS_URL + '?productId=' + productId)
        .done((res) => {
            productVariants = res;

            console.log(productVariants);

            if (productVariants.length > 1) {
                if ($VARIANT_OPTION_1_ELEMENTS) {
                    $VARIANT_OPTION_1_ELEMENTS.on('click', function (e) {
                        let $self = $(this);

                        // if the element is radio
                        option1 = $self.val();

                        // reseting the label
                        $(`label.selected`).removeClass('selected');

                        // resetting the radios
                        $VARIANT_OPTION_2_ELEMENTS.prop('checked', false);
                        $VARIANT_OPTION_3_ELEMENTS.prop('checked', false);

                        // resetting the selects
                        $VARIANT_OPTION_2_SELECT_ELEMENTS.val('');
                        $VARIANT_OPTION_3_SELECT_ELEMENTS.val('');

                        $(`label[for="option1-${option1}"]`).addClass('selected');

                        // get all variant with the
                        // chosen option 1
                        currentlySelectedVariants = productVariants.filter(v => v.Option1.toLowerCase() == option1.toLowerCase());

                        // if there is only one variants
                        // which means no option 2
                        if (currentlySelectedVariants.length == 1) {
                            chosenVariant = currentlySelectedVariants[0].Id;

                            if (IsVariantAvailable(currentlySelectedVariants[0])) {

                                EnableAddToCartElement();

                            }

                            UTILS.UPDATE_PRICE_VIEW_FROM_VARIANT(currentlySelectedVariants[0], $('[data-identity="product-price"]'), $('[data-identity="product-discount"]'));

                        }
                        else {
                            $VARIANT_OPTION_2_ELEMENTS.prop('disabled', false).removeClass('disabled');
                            $VARIANT_OPTION_3_ELEMENTS.prop('disabled', false).removeClass('disabled');

                            $VARIANT_OPTION_2_SELECT_ELEMENTS.find('option').prop('disabled', false).removeClass('disabled');
                            $VARIANT_OPTION_3_SELECT_ELEMENTS.find('option').prop('disabled', false).removeClass('disabled');

                            ResetChosenVariant();
                            DisableAddToCartElement()

                            currentlySelectedVariants.forEach((v) => {
                                console.log('test');
                                if (!IsVariantAvailable(v)) {

                                    $(`#option2-${v.Option2}`).prop('disabled', true).addClass('disabled');

                                }
                            });
                        }
                    });
                }

                if ($VARIANT_OPTION_1_SELECT_ELEMENTS) {
                    $VARIANT_OPTION_1_SELECT_ELEMENTS.on('change', function () {

                        const $self = $(this);

                        // if the element is radio
                        option1 = $self.val();

                        // reseting the label
                        $(`label.selected`).removeClass('selected');

                        // resetting the radios
                        $VARIANT_OPTION_2_ELEMENTS.prop('checked', false);
                        $VARIANT_OPTION_3_ELEMENTS.prop('checked', false);

                        // resetting the selects
                        $VARIANT_OPTION_2_SELECT_ELEMENTS.val('');
                        $VARIANT_OPTION_3_SELECT_ELEMENTS.val('');

                        // get all variant with the
                        // chosen option 1
                        currentlySelectedVariants = productVariants.filter(v => v.Option1.toLowerCase() == option1.toLowerCase());

                        // if there is only one variants
                        // which means no option 2
                        if (currentlySelectedVariants.length == 1) {
                            chosenVariant = currentlySelectedVariants[0].Id;

                            if (IsVariantAvailable(currentlySelectedVariants[0])) {

                                EnableAddToCartElement();

                            }

                            UTILS.UPDATE_PRICE_VIEW_FROM_VARIANT(currentlySelectedVariants[0], $('[data-identity="product-price"]'), $('[data-identity="product-discount"]'));

                        }
                        else {
                            $VARIANT_OPTION_2_ELEMENTS.prop('disabled', false).removeClass('disabled');
                            $VARIANT_OPTION_3_ELEMENTS.prop('disabled', false).removeClass('disabled');

                            $VARIANT_OPTION_2_SELECT_ELEMENTS.find('option').prop('disabled', false).removeClass('disabled');
                            $VARIANT_OPTION_3_SELECT_ELEMENTS.find('option').prop('disabled', false).removeClass('disabled');

                            ResetChosenVariant();
                            DisableAddToCartElement()

                            currentlySelectedVariants.forEach((v) => {
                                console.log('test');
                                if (!IsVariantAvailable(v)) {

                                    // giving disabled to second options
                                    $(`#option2-${v.Option2}`).prop('disabled', true).addClass('disabled');

                                }
                            });
                        }
                    });
                }

                if ($VARIANT_OPTION_2_ELEMENTS) {
                    $VARIANT_OPTION_2_ELEMENTS.on('click', function (e) {

                        let $self = $(this);

                        if (!$self.prop('disabled')) {

                            if (option1 != undefined) {

                                option2 = $self.val();

                            }
                            else {
                                e.preventDefault();
                                // @ts-ignore
                                Notify('Please choose the first option!', 'error');

                                return;
                            }

                            // get all variant with the
                            // chosen option 1 & 2
                            currentlySelectedVariants = productVariants.filter(v => v.Option1.toLowerCase() == option1.toLowerCase() && v.Option2.toLowerCase() == option2.toLowerCase());

                            if (currentlySelectedVariants.length == 1) {

                                chosenVariant = currentlySelectedVariants[0].Id;

                                if (IsVariantAvailable(currentlySelectedVariants[0])) {

                                    EnableAddToCartElement();

                                }

                                UTILS.UPDATE_PRICE_VIEW_FROM_VARIANT(currentlySelectedVariants[0], $('[data-identity="product-price"]'), $('[data-identity="product-discount"]'));

                            }
                            else {

                                $VARIANT_OPTION_3_ELEMENTS
                                    .prop('checked', false)
                                    .prop('disabled', false)
                                    .removeClass('disabled')
                                    .removeClass('selected');

                                $VARIANT_OPTION_3_SELECT_ELEMENTS.find('option').prop('disabled', false).removeClass('disabled');

                                $VARIANT_OPTION_3_SELECT_ELEMENTS.val('');

                                ResetChosenVariant();
                                DisableAddToCartElement()

                                currentlySelectedVariants.forEach((v) => {
                                    if (!IsVariantAvailable(v)) {

                                        $(`#option3-${v.Option3}`).prop('disabled', true).addClass('disabled');

                                    }
                                });

                            }

                        }
                        else {
                            // @ts-ignore
                            Notify('There is no stock for this variant!', 'error');

                        }

                    });
                }

                if ($VARIANT_OPTION_2_SELECT_ELEMENTS) {
                    $VARIANT_OPTION_2_SELECT_ELEMENTS.on('change', function (e) {

                        let $self = $(this);

                        if (option1 != undefined) {

                            option2 = $self.val();

                        }
                        else {
                            e.preventDefault();
                            // @ts-ignore
                            Notify('Please choose the first option!', 'error');

                            return;
                        }

                        // get all variant with the
                        // chosen option 1 & 2
                        currentlySelectedVariants = productVariants.filter(v => v.Option1.toLowerCase() == option1.toLowerCase() && v.Option2.toLowerCase() == option2.toLowerCase());

                        if (currentlySelectedVariants.length == 1) {

                            chosenVariant = currentlySelectedVariants[0].Id;

                            if (IsVariantAvailable(currentlySelectedVariants[0])) {

                                EnableAddToCartElement();

                            }

                            UTILS.UPDATE_PRICE_VIEW_FROM_VARIANT(currentlySelectedVariants[0], $('[data-identity="product-price"]'), $('[data-identity="product-discount"]'));

                        }
                        else {

                            $VARIANT_OPTION_3_ELEMENTS
                                .prop('checked', false)
                                .prop('disabled', false)
                                .removeClass('disabled')
                                .removeClass('selected');

                            $VARIANT_OPTION_3_SELECT_ELEMENTS.find('option').prop('disabled', false).removeClass('disabled');

                            $VARIANT_OPTION_3_SELECT_ELEMENTS.val('');

                            ResetChosenVariant();
                            DisableAddToCartElement()

                            currentlySelectedVariants.forEach((v) => {
                                if (!IsVariantAvailable(v)) {

                                    $(`#option3-${v.Option3}`).prop('disabled', true).addClass('disabled');

                                }
                            });

                        }


                    });
                }

                if ($VARIANT_OPTION_3_ELEMENTS) {
                    $VARIANT_OPTION_3_ELEMENTS.on('click', function (e) {
                        let $self = $(this);

                        if (option2 != null) {
                            option3 = $self.val();
                        }
                        else {
                            e.preventDefault();
                            // @ts-ignore
                            Notify('Please choose the second option!');

                            return
                        }

                        // get all variant with the
                        // chosen option 1 & 2
                        currentlySelectedVariants = productVariants.filter(v => v.Option1.toLowerCase() == option1.toLowerCase() && v.Option2.toLowerCase() == option2.toLowerCase() && v.Option3.toLowerCase() == option3.toLowerCase());

                        chosenVariant = currentlySelectedVariants[0].Id;

                        if (IsVariantAvailable(currentlySelectedVariants[0])) {

                            EnableAddToCartElement();

                        }

                        UTILS.UPDATE_PRICE_VIEW_FROM_VARIANT(currentlySelectedVariants[0], $('[data-identity="product-price"]'), $('[data-identity="product-discount"]'));

                    });
                }

                if ($VARIANT_OPTION_3_SELECT_ELEMENTS) {
                    $VARIANT_OPTION_3_SELECT_ELEMENTS.on('change', function (e) {
                        let $self = $(this);

                        if (option2 != null) {
                            option3 = $self.val();
                        }
                        else {
                            e.preventDefault();
                            // @ts-ignore
                            Notify('Please choose the second option!');

                            return
                        }

                        // get all variant with the
                        // chosen option 1 & 2
                        currentlySelectedVariants = productVariants.filter(v => v.Option1.toLowerCase() == option1.toLowerCase() && v.Option2.toLowerCase() == option2.toLowerCase() && v.Option3.toLowerCase() == option3.toLowerCase());

                        chosenVariant = currentlySelectedVariants[0].Id;

                        if (IsVariantAvailable(currentlySelectedVariants[0])) {

                            EnableAddToCartElement();

                        }

                        UTILS.UPDATE_PRICE_VIEW_FROM_VARIANT(currentlySelectedVariants[0], $('[data-identity="product-price"]'), $('[data-identity="product-discount"]'));

                    });
                }
            }
            else {
                currentlySelectedVariants = productVariants[0];

                // @ts-ignore
                chosenVariant = currentlySelectedVariants.Id;

                // @ts-ignore
                if (currentlySelectedVariants.Stock > 0) {

                    EnableAddToCartElement();

                }

            }

        });

    $ADD_TO_CART_ELEMENTS.on('click', function () {
        let $self = $(this);

        // if the button is disabled
        if ($self.hasClass('disabled')) {
            // @ts-ignore
            Notify(CHOOSE_VARIANT_MESSAGE, 'error');

        }
        else {

            // get the product id
            let productId = $self.data('productId');

            AddToCart(productId, chosenVariant, itemQuantity)
                .then(res => {

                    // notify
                    // @ts-ignore
                    Notify(SUCCESS_ADD_MESSAGE, 'success');

                    console.log(res);

                    // update the quantity
                    $CART_QUANTITY_ELEMENTS().text(res.data.quantity);

                    // if sidecart was empty
                    if ($('.bw-empty-cart').length) {
                        $SIDECART_ITEM_CONTAINER().empty();
                    }

                    // if item was exist on cart
                    // update the sidecart view
                    if ($SIDECART_ITEM_CONTAINER().find(`[data-identity="cart-item"][data-id="${res.data.item.ID}"]`).length) {
                        $SIDECART_ITEM_CONTAINER().find(`[data-identity="cart-item"][data-id="${res.data.item.ID}"]`).first().remove();
                    }

                    // appending the new item into sidecartview
                    $SIDECART_ITEM_CONTAINER().append(UTILS.CART_ITEM_ELEMENT_STRING(res.data.item));

                    // add delete cart item handler
                    $CART_ITEM_DELETE_ACTION_ELEMENT()
                        .off()
                        .click(function () {
                            let id = $(this).data('id');
                            let isUnique = $(this).data('isUnique');
                            let prompt = $(this).data('prompt');

                            let itemInfo = {
                                imageUrl: $(`[data-identity="cart-item-image"][data-id="${id}"]`).data('imageUrl'),
                                productName: $(`[data-identity="cart-item-name"][data-id="${id}"]`).data('name')
                            };

                            console.log(itemInfo);

                            if (prompt) {
                                // @ts-ignore
                                window[prompt].call(this, itemInfo)
                                    .then(res => {

                                        if (res) {
                                            if (isUnique != undefined && isUnique != null) {
                                                DeleteCartItem(id)
                                            }
                                            else {
                                                DeleteCartItem(id, isUnique);
                                            }
                                        }

                                    })
                            }

                        });

                    // add update cart item handler
                    $CART_ITEM_QUANTITY_INPUT()
                        .off('change')
                        .on('change', function () {
                            // logging
                            console.log('updating...');

                            // get the quantity
                            let qty = $(this).val();

                            if (qty <= 0) {
                                $(this).val(1);

                                // cancel the operation
                                return;
                            }

                            // get the cart id
                            let id = $(this).data('id');

                            // get unique
                            let isUnique = $(this).data('isUnique');

                            if (isUnique) {
                                UpdateCartItem(id, qty, isUnique);
                            }
                            else {
                                UpdateCartItem(id, qty);
                            }
                        });

                    // calculate subtotal cart
                    CalculateCart();

                    // clear selections
                    ResetChoices();

                    // unload spinner
                    UnloadSpinnerAddToCartElement();

                    // enable all variant options;
                    EnableAllVariantOptions();

                    // redisabled the add to cart button
                    if (productVariants.length > 1) {
                        DisableAddToCartElement();
                    }
                    else {
                        EnableAddToCartElement();
                    }
                })
                .catch(err => {
                    if (err.response.status == 400) {
                        // @ts-ignore
                        Notify(jqxhr.statusText, 'error');
                    }
                    else {
                        console.error('Error code: ' + err.response.status);
                        // @ts-ignore
                        Notify(FAIL_ADD_MESSAGE, 'error');
                    }
                });
        }
    });
}

/*
 * =============================
 * Update Cart Item
 * =============================
 */
if ($CART_ITEM_QUANTITY_INPUT().length) {
    $CART_ITEM_QUANTITY_INPUT()
        .off('change')
        .on('change', function () {
            // logging
            console.log('updating...');

            // get the quantity
            let qty = $(this).val();

            if (qty <= 0) {
                $(this).val(1);

                // cancel the operation
                return;
            }

            // get the cart id
            let id = $(this).data('id');

            // get unique
            let isUnique = $(this).data('isUnique');

            if (isUnique) {
                UpdateCartItem(id, qty, isUnique);
            }
            else {
                UpdateCartItem(id, qty);
            }
        });
}
/*
 * =============================
 * Update Cart Item .\END
 * =============================
 */

if ($SHIPPING_ADDRESS_SUBMIT_BUTTON.length) {
    $SHIPPING_ADDRESS_SUBMIT_BUTTON.on('click', function () {
        let chosenAddress = $('input[name="shippingAddress"]:checked').val();

        console.log(chosenAddress);

        $.post('/umbraco/surface/cart/addshipmentaddressajax', { addressId: chosenAddress })
            .done(res => {
                window.location.reload();
            })
            .fail(err => {
                // @ts-ignore
                Toaster({
                    text: err.statusText,
                    duration: 5000,
                    backgroundColor: TOASTER_ERROR_BG_COLOR,
                    position: 'right',
                    gravity: 'bottom'
                });

                console.error(err);
            });
    });
}

function OnBeginAddDeliveryCourier() {
    $DELIVERY_COURIER_SUBMIT_BUTTON
        .prop('disabled', true)
        .addClass('disabled');
}

// expose it to global.
// @ts-ignore
window.OnBeginAddDeliveryCourier = OnBeginAddDeliveryCourier;

function OnFailureAddDeliveryCourier(err) {
    $DELIVERY_COURIER_SUBMIT_BUTTON
        .prop('disabled', false)
        .removeClass('disabled');

    console.log(err);

    if (err.status == 500) {
        // @ts-ignore
        Toaster({
            text: DELIVERY_COURIER_ERROR_MESSAGE,
            duration: 5000,
            backgroundColor: TOASTER_ERROR_BG_COLOR,
            position: 'right',
            gravity: 'bottom'
        });
    }
    else {
        // @ts-ignore
        Toaster({
            text: err.statusText,
            duration: 5000,
            backgroundColor: TOASTER_ERROR_BG_COLOR,
            position: 'right',
            gravity: 'bottom'
        });
    }
}

// expose it to global.
// @ts-ignore
window.OnFailureAddDeliveryCourier = OnFailureAddDeliveryCourier;

function OnSuccessAddDeliveryCourier() {
    window.location.reload();
}

// expose it to global.
// @ts-ignore
window.OnSuccessAddDeliveryCourier = OnSuccessAddDeliveryCourier;

if ($DELIVERY_COURIER_RADIO.length) {
    $DELIVERY_COURIER_RADIO.change(function () {
        // get name
        let courierName = $(this).data('name');

        // get service
        let courierService = $(this).data('service');

        // get cost
        let courierCost = $(this).data('price');

        // put the value in
        $DELIVERY_COURIER_NAME_INPUT.val(courierName);

        // put the value in
        $DELIVERY_COURIER_SERVICE_INPUT.val(courierService);

        // put the value in
        $DELIVERY_COURIER_COST_INPUT.val(courierCost);

        console.log($DELIVERY_COURIER_COST_INPUT.val());
    });
}

if ($PAYMENT_RADIO.length) {
    $PAYMENT_RADIO.change(function () {
        // get bank id
        let bankId = $(this).val();

        // get payment type
        let paymentType = $(this).data('paymentType');

        $PAYMENT_BANK_INPUT.val(bankId);

        $PAYMENT_TYPE_INPUT.val(paymentType);

        console.log(bankId + " " + paymentType);
    });
}

function OnBeginAddPaymentAndNote() {
    $PAYMENT_AND_NOTE_SUBMIT_BUTTON
        .prop('disabled', true)
        .addClass('disabled');
}

// expose it to global.
// @ts-ignore
window.OnBeginAddPaymentAndNote = OnBeginAddPaymentAndNote;

function OnFailureAddPaymentAndNote(err) {
    $PAYMENT_AND_NOTE_SUBMIT_BUTTON
        .prop('disabled', false)
        .removeClass('disabled');

    if (err.status == 500) {
        // @ts-ignore
        Toaster({
            text: PAYMENT_AND_NOTE_ERROR_MESSAGE,
            duration: 5000,
            backgroundColor: TOASTER_ERROR_BG_COLOR,
            position: 'right',
            gravity: 'bottom'
        });
    }
    else {
        // @ts-ignore
        Toaster({
            text: err.statusText,
            duration: 5000,
            backgroundColor: TOASTER_ERROR_BG_COLOR,
            position: 'right',
            gravity: 'bottom'
        });
    }
}

// expose it to global.
// @ts-ignore
window.OnFailureAddPaymentAndNote = OnFailureAddPaymentAndNote;

function OnSuccessAddPaymentAndNote() {
    //window.location.href = '/completed/';
    window.location.reload();
}

// expose it to global.
// @ts-ignore
window.OnSuccessAddPaymentAndNote = OnSuccessAddPaymentAndNote;


// checkout
import {
    CompleteTransaction
} from './checkout';


// conventional bank transfer
if ($COMPLETE_TRANSACTION_BUTTON().length) {
    $COMPLETE_TRANSACTION_BUTTON().on('click', function () {

        $(this).addClass('disabled').prop('disabled', true);

        $.post(COMPLETE_TRANSACTION_URL)
            .then(res => {
                window.location.href = '/completed';
            })

    });
}

// payment gateway
import PaymentGatewayInfo from './interfaces/payment-gateway-info.interface';
import PaymentGatewayMethod from './interfaces/payment-gateway-method.interface';
import PaymentType from './interfaces/payment-type.interface';

// payment gateway - midtrans
import MidtransTransactionResponse from './interfaces/midtrans/midtrans-transaction-response.interface';
import SnapCallback from './interfaces/midtrans/snap-callback.interface';

import {
    PayWithMidtrans,
    SavePaymentGatewayInfo,    SnapMidtransModal
} from './payment-gateway/midtrans';



if ($PAY_WITH_MIDTRANS_BUTTON().length) {
    $PAY_WITH_MIDTRANS_BUTTON().on('click', function () {

        $(this).addClass('disabled').prop('disabled', true);

        // is direct debit
        const isDirectDebit = $(this).data('isDirectDebit') as boolean;

        // process with midtrans
        PayWithMidtrans(isDirectDebit)
            .then(res => {
                const response: MidtransTransactionResponse = res.data as MidtransTransactionResponse;

                const info: PaymentGatewayInfo = {
                    Id: null,
                    InvoiceNumber: response.OrderId,
                    Status: "Pending",
                    PaymentGatewayMethod: PaymentGatewayMethod.Unknown,
                    PaymentGateway: isDirectDebit ? PaymentType.MidtransDirectDebit : PaymentType.Midtrans,
                    Token: response.token,
                    RedirectUri: response.redirect_url
                }

                if (response.token != null && response.token != undefined) {

                    if (isDirectDebit) {
                        // complete the transaction
                        CompleteTransaction(response.OrderId)
                            .then(() => {

                                SavePaymentGatewayInfo(info)
                                    .then(() => {
                                        window.open(response.redirect_url, '_blank');
                                        window.location.href = '/completed';
                                    })
                                    .catch(err => {
                                        console.error(err);
                                    });
                            });
                    }
                    else {
                        // create callbacks
                        const callbacks: SnapCallback = {
                            successCallback: () => {
                                CompleteTransaction(response.OrderId)
                                    .then(() => {
                                        SavePaymentGatewayInfo(info)
                                            .then(() => {
                                                window.location.href = '/completed';
                                            });

                                    })
                                    .catch(err => {
                                        console.error(err);
                                    });
                            },
                            pendingCallback: () => {
                                CompleteTransaction(response.OrderId)
                                    .then(() => {
                                        SavePaymentGatewayInfo(info)
                                            .then(() => {
                                                window.location.href = '/completed';
                                            });
                                    })
                                    .catch(err => {
                                        console.error(err);
                                    });
                            },
                            errorCallback: () => {
                                // @ts-ignore
                                window.Toaster({
                                    gravity: 'top',
                                    position: 'center',
                                    backgroundColor: TOASTER_ERROR_BG_COLOR,
                                    text: 'There has been an error processing your payment. Please try again later!',
                                    duration: 6000
                                });
                            },
                            closeCallback: () => {
                                // @ts-ignore
                                $PAY_WITH_MIDTRANS_BUTTON().removeClass('disabled').prop('disabled', false);
                            }
                        }

                        SnapMidtransModal(response.token, callbacks);
                    }

                }
            })
            .catch(err => {
                console.error(err);

                // @ts-ignore
                Toaster({
                    gravity: 'top',
                    position: 'center',
                    backgroundColor: TOASTER_ERROR_BG_COLOR,
                    text: 'There has been an error processing your payment. Please try again later!',
                    duration: 6000
                })
            });
    });
}


// importing others ecommerce
// modules

// wishlist
import './wishlist';

// promo
import './promo/automatic-promo';
import './promo/coupon-code';
import './promo/change-free-item';

// cart
import './cart';

// confirm payment
import './confirm-payment';

// quick view
import './product-quick-view';

